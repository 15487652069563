/**
 * Place any jQuery/helper plugins in here.
 */
$(function () {
    /**
     * Checkbox tree for permission selecting
     */
    $('body').on('click', '.portfolio-itemx > a', function(e){
       
        e.preventDefault();
        let itemParent = $(this).parent();
        $('.portfolio-item').removeClass('expanded');
        $('.portfolio-item').not(itemParent).addClass('hovered');
        itemParent.addClass('expanded');
    });


});
